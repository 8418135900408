<template>
    <div class="dashboard-alerts-figure">
        <img
            alt="Alert Figure"
            :src="imageSrc"
            :style="imageStyle"
        >
    </div>
</template>

<script>
    export default {
        props: {
            alert: {
                type: Object,
                required: true
            }
        },
        computed: {
            imageSrc() {
                return require('./assets/img/'+ this.alert.image.src);
            },
            imageStyle() {
                return {
                    width: this.alert.image.width + 'px',
                    marginBottom: this.alert.image.marginBottom + 'px',
                    marginLeft: this.alert.image.marginLeft + 'px',
                    marginRight: this.alert.image.marginRight + 'px'
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    .dashboard-alerts-figure {
        // border: 1px dashed orange;
        margin-left: 45px;
        will-change: transform; // necessary?
    }
</style>