<template>
  <div class="dashboard-tv-parent">
    <!-- DASHBOARD TV -->
    <div class="dashboard-tv" :class="dashboardTVClasses">
      <!-- TV ANTENNA LEFT -->
      <div class="tv-antenna-left"></div>

      <!-- TV ANTENNA RIGHT -->
      <div class="tv-antenna-right"></div>

      <!-- TV SCREEN -->
      <div class="tv-screen">
        <!-- TV SCREEN LOGO -->
        <span
          v-show="logo"
          class="tv-screen-logo animated zoomInDown"
          :style="logoAnimationDisabled && { animation: 'none' }"
        >
          <!-- LIVE -->
          <span
            class="tv-screen-logo-live animated bounceIn"
            :style="logoAnimationDisabled && { animation: 'none' }"
          >
          </span>
        </span>

        <!-- TV SCREEN STATIC -->
        <transition :css="false" @enter="enterStatic" @leave="leaveStatic">
          <div v-if="static && !off" :class="randomStaticClass()" class="tv-screen-static"></div>
        </transition>
      </div>

      <!-- TV SPRITE -->
      <img class="tv-sprite" width="438" src="./assets/img/tv.svg" alt="TV Sprite" />

      <!-- TV KNOB TOP -->
      <div class="tv-knob-top"></div>

      <!-- TV KNOB BOTTOM -->
      <div
        class="tv-knob-bottom"
        :style="{ transform: 'rotate(' + this.channelKnobRotation + 'deg)' }"
      ></div>

      <!-- TV LIGHT -->
      <div class="tv-light"></div>

      <!-- TV PADLOCK -->
      <div v-show="playerLock" class="tv-padlock">
        <div class="tv-padlock-padlock"></div>
        <div class="tv-padlock-chains"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        channelKnobRotation: 0,
        shakeAntenna: false,
        shakeAntennaTimeoutInstance: null,
      };
    },
    computed: {
      ...mapGetters({
        getActiveTask: "tasks/getActiveTask",
        getActiveVideoIndex: "youtube/getActiveVideoIndex",
      }),
      dashboardTVClasses() {
        return {
          "is-on": !this.off,
          "is-clear": this.clear,
          "is-receiving": this.shakeAntenna,
        };
      },
      clear() {
        return !!this.getActiveTask("dashboardTVChannel", "Clear");
      },
      playerLock() {
        return (
          !!this.getActiveTask("youTubePlayer", "Lock") ||
          !!this.getActiveTask("lockTaskGroup", "YouTubePlaylist")
        ); // TODO: make this universal | oof backwards compat with v1/2 -- hack job - lock isn't important anyway
      },
      logo() {
        return !!this.getActiveTask("dashboardTVChannel", "Logo");
      },
      logoAnimationDisabled() {
        return (
          !!this.getActiveTask("dashboardTVChannel", "Logo") &&
          this.getActiveTask("dashboardTVChannel", "Logo").data.disableAnimation
        );
      },
      off() {
        return !!this.getActiveTask("dashboardTVChannel", "Off");
      },
      static() {
        return !!this.getActiveTask("dashboardTVChannel", "Static");
      },
      youTubePlayer() {
        return !!this.getActiveTask("youTubePlayer", "Run");
      },
      youTubePlaylistPlay() {
        return !!this.getActiveTask("youTubePlaylist", "Play"); // Needed for getActiveVideoIndex watcher
      },
      youTubeRequestIncoming() {
        return this.getActiveTask("youTubePlaylist", "Request Video");
      },
      twitchRequestIncoming() {
        return this.getActiveTask("twitchPlayer", "Request Media");
      },
    },
    watch: {
      //------------------------------------------------------------
      // WATCH: GET ACTIVE VIDEO INDEX
      //------------------------------------------------------------

      getActiveVideoIndex() {
        // Flash STATIC only if playing then CLEAR TV for showing.
        // Requires an accompanying 'play' task, so removing previous songs (which triggers an array mutation)
        // won't trigger this unecessarily.
        if (this.youTubePlayer && this.youTubePlaylistPlay) {
          this.$store.dispatch("tasks/runTasks", [
            { uuid: "3f828ebf-b109-4074-bcc0-23086a3872cd" }, // clear
            { uuid: "749fce6e-7ff6-44d0-868f-a9883a712813", canceled: false }, // static
          ]);
        }
      },

      //------------------------------------------------------------
      // WATCH: PLAYER LOCK (RENAME LATER)
      //------------------------------------------------------------

      playerLock(newVal, oldVal) {
        if (!!newVal && newVal !== oldVal) {
          this.$playSound("TV-lock", { interrupt: true, volume: 0.05 });
        }
      },

      //------------------------------------------------------------
      // WATCH: OFF
      //------------------------------------------------------------

      off(newVal, oldVal) {
        if (!!newVal && newVal !== oldVal) {
          this.$stopAllSounds();
          this.$playSound("TV-on", { interrupt: true, volume: 0.4 });
        }
      },

      //------------------------------------------------------------
      // WATCH: STATIC
      //------------------------------------------------------------

      static() {
        if (!this.off) {
          this.channelKnobRotation = Math.round(Math.random() * 250);
          this.$playSound("TV-dial", { interrupt: true, volume: 0.1 });
        }
      },

      //------------------------------------------------------------
      // WATCH: YOUTUBE REQUEST INCOMING
      //------------------------------------------------------------

      youTubeRequestIncoming(newVal, oldVal) {
        if (!!newVal && newVal !== oldVal) {
          this.shakeAntenna = true;
          clearTimeout(this.shakeAntennaTimeoutInstance);
          this.shakeAntennaTimeoutInstance = setTimeout(() => (this.shakeAntenna = false), 3000);
        }
      },

      //------------------------------------------------------------
      // WATCH: YOUTUBE PLAYER (todo rewrite?)
      //------------------------------------------------------------

      youTubePlayer(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (!!newVal) {
            // YouTube Player program clears tv and flashes static on INIT.
            this.$store.dispatch("tasks/runTasks", [
              { uuid: "3f828ebf-b109-4074-bcc0-23086a3872cd", canceled: false }, // force clear
              { uuid: "749fce6e-7ff6-44d0-868f-a9883a712813", canceled: false, duration: 2000 }, // static
            ]);
          } else {
            // Unless the TV is off, flash static and default back to the logo on EXIT.
            if (!this.getActiveTask("dashboardTVChannel", "Off")) {
              this.$store.dispatch("tasks/runTasks", [
                { uuid: "cdd86256-a166-4b33-a66a-70f01bdcfcfa", canceled: false }, // force logo
                { uuid: "749fce6e-7ff6-44d0-868f-a9883a712813", canceled: false, duration: 2000 }, // force static (infinite)
              ]);
            }
          }
        }
      },

      //------------------------------------------------------------
      // WATCH: TWITCH REQUEST INCOMING (REFACTOR - DUP)
      //------------------------------------------------------------

      twitchRequestIncoming(newVal, oldVal) {
        if (!!newVal && newVal !== oldVal) {
          this.shakeAntenna = true;
          clearTimeout(this.shakeAntennaTimeoutInstance);
          this.shakeAntennaTimeoutInstance = setTimeout(() => (this.shakeAntenna = false), 3000);
        }
      },
    },
    methods: {
      //------------------------------------------------------------
      // ENTER STATIC
      //------------------------------------------------------------

      enterStatic(el, done) {
        this.$playSound("static-loop-x4", { interrupt: true, loop: true, volume: 0.03 });
      },

      //------------------------------------------------------------
      // LEAVE STATIC
      //------------------------------------------------------------

      leaveStatic(el, done) {
        el.style.opacity = 0;
        this.$stopSound("static-loop-x4");
      },

      //------------------------------------------------------------
      // RANDOM STATIC CLASS
      //------------------------------------------------------------

      randomStaticClass() {
        let rand = Math.floor(Math.random() * 6) + 1;

        // roll again. make 5: jason and 6: party of five more scarce
        if (rand == 5 || rand == 6) {
          rand = Math.floor(Math.random() * 6) + 1;
          if (rand == 5 || rand == 6) rand = Math.floor(Math.random() * 6) + 1;
        }

        return "is-static-" + rand;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .dashboard-tv-parent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // border: 1px dashed aqua;
    pointer-events: none;
  }

  //---------------------------------------------------------
  // DASHBOARD TV
  //---------------------------------------------------------

  .dashboard-tv {
    position: absolute;
    right: rem-calc(-93);
    bottom: rem-calc(-41);
    transform: rotate(-15deg);
    // border: 1px dashed yellow;
  }

  //---------------------------------------------------------
  // TV SCREEN
  //---------------------------------------------------------

  .tv-screen {
    position: absolute;
    left: rem-calc(24);
    bottom: rem-calc(46);
    width: rem-calc(244);
    height: rem-calc(196);
    // border: 1px dashed orange;
    background-color: black;
    overflow: hidden;
    z-index: -1;
    transition: background-color 200ms ease;
  }

  .is-on .tv-screen {
    background-color: lighten(black, 10%);
  }

  .is-clear .tv-screen {
    background: transparent;
  }

  //---------------------------------------------------------
  // TV SCREEN LOGO
  //---------------------------------------------------------

  .tv-screen-logo {
    position: absolute;
    top: 0;
    left: rem-calc(-10);
    width: 100%;
    height: 100%;
    background: url("./assets/img/tv-logo-6.png") no-repeat center center;
    background-size: rem-calc(176);
    animation-duration: 1.5s;
    // z-index: 10000;
  }

  .is-clear .tv-screen-logo {
    visibility: hidden;
  }

  .tv-screen-logo-live {
    position: absolute;
    top: rem-calc(-12);
    right: rem-calc(-100);
    width: 100%;
    height: 100%;
    background: url("./assets/img/tv-logo-live.svg") no-repeat center center;
    background-size: 22px;
    animation-delay: 1.3s;
    animation-duration: 1s;
    z-index: 1;
  }

  .tv-screen-static {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    // background-size: cover; // reference only
    background-size: 140%; // [LOCKED] - looks better
    z-index: 2;

    &.is-static-1 {
      background-image: url("./assets/img/tv-static-1.gif");
    }
    &.is-static-2 {
      background-image: url("./assets/img/tv-static-2.gif");
    }
    &.is-static-3 {
      background-image: url("./assets/img/tv-static-3.gif");
    }
    &.is-static-4 {
      background-image: url("./assets/img/tv-static-4.gif");
      background-size: 150%;
    }
    &.is-static-5 {
      background-image: url("./assets/img/tv-static-5.gif");
      background-size: 100%;
    }
    &.is-static-6 {
      background-image: url("./assets/img/tv-static-6.gif");
      background-size: 140%;
    }
  }

  //--------------------------------------------------------
  // TV KNOBS
  //--------------------------------------------------------

  .tv-knob-top,
  .tv-knob-bottom {
    position: absolute;
    bottom: rem-calc(214);
    right: rem-calc(115);
    width: rem-calc(28);
    height: rem-calc(28);
    background: url("./assets/img/tv-knob.svg") no-repeat center center;
    background-size: contain;
    transform: rotate(-50deg);
    transition: transform 80ms ease;
    // border: 0.5px solid red;
  }

  .is-on .tv-knob-top {
    transform: rotate(30deg);
  }

  .tv-knob-bottom {
    bottom: rem-calc(173);
    right: rem-calc(114.5);
    transition: 200ms linear transform;
    // will-change: transform; // WARNING: this causes lossiness when parent is zoomed
  }

  //--------------------------------------------------------
  // TV LIGHT
  //--------------------------------------------------------

  .tv-light {
    position: absolute;
    bottom: rem-calc(43);
    right: rem-calc(140);
    width: rem-calc(4);
    height: rem-calc(4);
    background: linear-gradient(to bottom, #ed000d 0%, #820013 100%);
    border-radius: 100%;
    opacity: 0;
    // animation: lightFade 4s linear 1s forwards infinite;
    z-index: 1;

    // @keyframes lightFade {
    //     0%, 100%, 50% { opacity: 0 }
    //     20%, 30% { opacity: 0.9 }
    // }
  }

  .is-on .tv-light {
    opacity: 1;
  }

  //---------------------------------------------------------
  // TV PADLOCK
  //---------------------------------------------------------

  .tv-padlock {
    position: absolute;
    top: rem-calc(172);
    left: rem-calc(2);
    width: calc(#{rem-calc(248 / 2)});
    height: calc(#{rem-calc(214 / 2)});
    z-index: 1;

    .tv-padlock-padlock {
      position: absolute;
      bottom: rem-calc(2);
      left: rem-calc(34);
      width: rem-calc(53);
      height: rem-calc(65);
      background: url("./assets/img/tv-padlock.svg") no-repeat top left;
      background-size: rem-calc(40);
      z-index: -1;
      // transform: rotate(-30deg);
      transform-origin: 30% 15%;
      animation: lock-jiggle 4s linear 0s alternate infinite forwards;

      @keyframes lock-jiggle {
        0%,
        100% {
          transform: rotate(-8deg);
        }
        50% {
          transform: rotate(6deg);
        }
      }
    }

    .tv-padlock-chains {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("./assets/img/tv-padlock-chains.svg") no-repeat top left;
      background-size: rem-calc(124);
    }
  }

  .is-tv-zoomed .tv-padlock .tv-padlock-padlock,
  .is-performance-mode .tv-padlock .tv-padlock-padlock {
    animation: none;
  }

  //--------------------------------------------------------
  // TV ANTENNA
  //--------------------------------------------------------

  .tv-antenna-left,
  .tv-antenna-right {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("./assets/img/tv-antenna-left.svg") no-repeat top left;
    background-size: rem-calc(438);
    transition: transform 500ms ease;
    z-index: 1;
  }

  .tv-antenna-right {
    background-image: url("./assets/img/tv-antenna-right.svg");
  }

  .is-receiving .tv-antenna-left {
    animation: shakeAntennaLeft 3s ease 0s forwards infinite;

    @keyframes shakeAntennaLeft {
      0%,
      100%,
      50% {
        transform: rotate(0deg);
      }
      20%,
      30% {
        transform: rotate(4deg);
      }
      40%,
      70% {
        transform: rotate(-2deg);
      }
    }
  }

  .is-receiving .tv-antenna-right {
    animation: shakeAntennaRight 3s ease 0s forwards infinite;

    @keyframes shakeAntennaRight {
      0%,
      20%,
      100%,
      50% {
        transform: rotate(0deg);
      }
      10%,
      30% {
        transform: rotate(-4deg);
      }
      40%,
      80%,
      90% {
        transform: rotate(-2deg);
      }
    }
  }

  //--------------------------------------------------------
  //
  //--------------------------------------------------------
</style>
