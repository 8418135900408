<template>
    <div class="dashboard-alerts">

        <!-- ALERTS FIGURE -->
        <transition
            enter-active-class="alertsFigureEnter animated slideInUp"
            leave-active-class="alertsFigureLeave animated slideOutDown"
            mode="out-in"
        >
            <AlertsFigure
                v-if="dynamicKey"
                :alert="dynamicKey"
                :key="dynamicKey.image.src"
            />
        </transition>

        <!-- ALERTS BUBBLE -->
        <transition
            enter-active-class="alertsBubbleEnter animated bounceIn"
            leave-active-class="alertsBubbleLeave animated zoomOut"
            mode="out-in"
        >
            <div
                v-if="dynamicKey"
                :key="dynamicKey.title"
                class="alerts-bubble ml-4"
            >
                <!-- ALERTS BUBBLE HEADING -->
                <h3 class="alerts-bubble-heading mb-0">
                    {{ dynamicKey.title }}
                </h3>

                <!-- ALERTS BUBBLE BODY -->
                <div
                    class="alerts-bubble-body"
                    v-html="dynamicKey.body"
                >
                </div>

            </div>
        </transition>

    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import DashboardAlertsFigure from './DashboardAlertsFigure.vue';

    export default {
        components: {
            AlertsFigure: DashboardAlertsFigure
        },
        props: {
            // currentScene: {
            //     type: Object,
            //     required: true
            // }
        },
        data() {
            return {
                delay: 1000,
                dynamicKey: null,
                timeout: null
            }
        },
        computed: {
            ...mapGetters({
                currentScene: 'scenes/getCurrentScene',
                stingerActive: 'scenes/getSceneTransitionStatus'
            }),
            alert() {
                return this.currentScene.data.sceneData.alert;
            }
        },
        watch: {
            stingerActive() {
                this.updateKey();
            }
        },
        created() {
            this.dynamicKey = this.alert;
        },
        methods: {
            updateKey() {
                if (!this.stingerActive) {
                    this.dynamicKey = this.alert;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    //--------------------------------------------------------
    // TRANSITIONS
    //--------------------------------------------------------

    .alertsFigureEnter {
        animation-delay: 300ms;
        animation-duration: 500ms;
    }

    .alertsBubbleEnter {
        animation-delay: 200ms;
        animation-duration: 800ms;
    }


    .alertsFigureLeave {
        animation-duration: 500ms;
        // animation-delay: 1200ms;
    }

    .alertsBubbleLeave {
        animation-duration: 800ms;
        animation-delay: 60ms; // [LOCKED]
    }

    //--------------------------------------------------------
    // DASHBOARD ALERTS
    //--------------------------------------------------------

    .dashboard-alerts {
        display: flex;
        width: 28%;
        // width: 604px;
        align-items: flex-end;
        position: relative;
        // top: 13px; //10px
        height: 115px;
        // border: 1px dashed aqua;
    }

    //--------------------------------------------------------
    // ALERTS BUBBLE
    //--------------------------------------------------------

    .alerts-bubble {
        position: relative;
        top: -1px;
        display: flex;
        flex-direction: column;
        margin: auto 0;
        max-width: 270px;
        max-height: 88px;
        min-height: 58px;
        padding: 11px 15px;
        // border: 1px dashed green;
        border-radius: 10px;
        font-size: rem-calc(14);
        font-weight: 400;
        line-height: 1.4;
        background: #343640;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: -30px;
            width: 37px;
            height: 100%;
            // border: 1px solid aqua;
            background: url('./assets/img/dashboard-alerts-bubble-tip.svg') no-repeat center 15%;
            background-size: 100%;
        }
    }

    //--------------------------------------------------------
    // ALERTS BUBBLE HEADING + BODY
    //--------------------------------------------------------

    .alerts-bubble-heading {
        font-size: 16px;
        color: $primary;
    }

    .alerts-bubble-body {
        max-height: 55px;
        color: white;
        overflow: hidden;
    }
</style>