<template>
    <div class="dashboard-heading">
        <div
            v-if="headingStyle"
            :key="headingStyle.backgroundImage"
        >
            <!-- SUBTITLE -->
            <h3 class="subtitle animated fadeInDown">
                What's On
            </h3>

            <!-- H2 -->
            <h2 class="mb-0 animated flipInX">

                <!-- IMG -->
                <span
                    class="img"
                    :style="headingStyle"
                >
                </span>
            </h2>

        </div>
    </div>
</template>

<script>
    export default {
        props: {
            currentScene: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                delay: 1000,
                headingStyle: null
            }
        },
        computed: {
            imageSrc() {
                return require('./assets/img/'+ this.currentScene.data.sceneData.src);
            }
        },
        watch: {
            imageSrc() {
                this.updateStyle();
            }
        },
        created() {
            this.updateStyle();
        },
        methods: {
            // this delays the title change - occurs behind Stinger
            updateStyle() {
                setTimeout(() => {
                    this.headingStyle = {
                        backgroundImage: 'url('+ this.imageSrc +')',
                        height: this.currentScene.data.sceneData.height + 'px'
                    }
                }, this.delay);
            }
        }
    }
</script>

<style lang="scss" scoped>

    .dashboard-heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: rem-calc(180); // curated
        // border: 1px solid red;
        text-align: center;
    }

    .dashboard-heading .subtitle {
        margin-bottom: 3px;
        font-family: 'Avenir Next Condensed';
        font-size: rem-calc(14); // 13
        font-weight: bold;
        text-transform: uppercase;
        color: $primary;
        animation-delay: 100ms;
        animation-duration: 200ms;
    }

    .dashboard-heading .img {
        display: block;
        width: 125px;
        // border: 1px dashed grey;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center center;
    }
</style>