<template>
  <div class="dashboard-parent">
    <!-- DASHBOARD ALERTS -->
    <DashboardAlerts />

    <!-- DASHBOARD -->
    <div class="dashboard is-active">
      <!-- DASHBOARD BG MASK -->
      <div class="dashboard-bg-mask">
        <!-- DASHBOARD BG FILL -->
        <div class="dashboard-bg-fill"></div>

        <!-- DASHBOARD LIP -->
        <div class="dashboard-lip">
          <div class="dashboard-lip-bg">
            <div class="dashboard-lip-bg-shine"></div>
          </div>
        </div>
      </div>

      <!-- DASHBOARD BODY -->
      <div class="dashboard-body">
        <!-- LURKERS COUNT -->
        <DashboardPanel
          name="Viewers"
          :onFire="goals.viewers.onFire"
          :text="viewerCount"
        />

        <!-- FOLLOWER COUNT -->
        <!-- :text-addition="'920'" -->
        <DashboardPanel
          name="Followers"
          :onFire="goals.followers.onFire"
          :text="followerCount"
        />

        <!-- DASHBOARD HEADING -->
        <DashboardHeading :current-scene="currentScene" />

        <!-- LATEST FOLLOW -->
        <DashboardPanel
          name="Latest"
          :onFire="goals.followers.onFire"
          :text="recentFollowers"
        />

        <!-- SUBSCRIBER COUNT -->
        <!-- :text-addition="'5'" -->
        <DashboardPanel
          name="Subs"
          :onFire="goals.subs.onFire"
          :text="subscriberCount"
          :text-addition="''"
        />
      </div>
    </div>

    <!-- DASHBOARD TV -->
    <DashboardTV class="dashboardTV-enter" />
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import DashboardAlerts from "./DashboardAlerts/DashboardAlerts.vue";
  import DashboardHeading from "./DashboardHeading/DashboardHeading.vue";
  import DashboardPanel from "./DashboardPanel/DashboardPanel.vue";
  import DashboardTV from "./DashboardTV/DashboardTV.vue";

  export default {
    components: {
      DashboardAlerts,
      DashboardHeading,
      DashboardPanel,
      DashboardTV,
    },
    data() {
      return {
        startingFollowerCount: null,
        twitchPollingInstance: null,

        goals: {
          followers: {
            cooldown: 1 * 10 * 1000, // How long to wait before initiating next goal.
            goals: [500, 510, 520, 530, 540, 550],
            goalsHit: [],
            onFire: false,
            startingCount: null,
            timeoutInstance: null,
          },
          subs: {
            cooldown: 1 * 10 * 1000, // How long to wait before initiating next goal.
            goals: [25, 50, 100, 125, 150, 200],
            // goalsHit: [],
            goalHit: false,
            onFire: false,
            startingCount: null,
            timeoutInstance: null,
          },
          viewers: {
            cooldown: 1 * 10 * 1000, // How long to wait before initiating next goal.
            goals: [20, 30, 40, 50, 60, 70, 80, 90, 100],
            // goalsHit: [],
            goalHit: false,
            onFire: false,
            startingCount: null,
            timeoutInstance: null,

            // cooldown: 1*10*1000, // How long to wait before initiating next goal.
            // currentGoal: null,
            // goalsHit: [], // Store goals already accomplished in session. eg. [20, 30, 40]
            // min: 20, // Goal starts at 20 viewers.
            // multiplier: 10, // Goals set every 10 viewers.
            // onFire: false, // Play animation.
            // startingCount: null,
            // lossThreshold: 4, // How many to lose before falling back to previous goal.
            // timeoutInstance: null,
            // timeoutDuration: 5000
          },
        },
      };
    },
    computed: {
      ...mapGetters({
        currentScene: "scenes/getCurrentScene",
        followerCount: "twitch/getFollowerCount",
        // followerGoal: 'twitch/getFollowerGoal',
        getActiveTask: "tasks/getActiveTask",
        latestFollowers: "twitch/getLatestFollowers",
        latestFollower: "twitch/getLatestFollower",
        subscriberCount: "twitch/getSubscriberCount",
        viewerCount: "twitch/getViewerCount",
        getViewerCountPollingPaused: "twitch/getViewerCountPollingPaused",
      }),
      recentFollowers() {
        return (
          this.latestFollowers
            .slice(0, 8)
            .map((user) => user?.user_name)
            .join(", ") || "???"
        );
      },
    },
    watch: {
      followerCount(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal >= 1010 && !this.goals.followers.goalHit) {
            this.goals.followers.goalHit = true;
            this.goals.followers.onFire = true;
            this.goals.followers.timeoutInstance = setTimeout(
              () => (this.goals.followers.onFire = false),
              3 * 60 * 1000
            );
          }
        }
      },
      subscriberCount(newVal, oldVal) {
        if (newVal !== oldVal) {
          // start at 59
          // 1. find nearest goal
          // const closestGoal = this.goals['subs'].goals.reduce((a, b) => Math.abs(b - newVal) < Math.abs(a - newVal) ? b : a);
          // console.log('closest goal is', closestGoal)

          // THIS ONE:
          // https://stackoverflow.com/questions/4811536/find-the-number-in-an-array-that-is-closest-to-a-given-number
          // If using the console to 'ytremove <num>' catch invalids:
          // const closestIncrement = volumeIncrements.sort((a, b) => Math.abs(volume - a) - Math.abs(volume - b))[0];

          if (newVal >= 30 && !this.goals.subs.goalHit) {
            this.goals.subs.goalHit = true;
            this.goals.subs.onFire = true;
            this.goals.subs.timeoutInstance = setTimeout(
              () => (this.goals.subs.onFire = false),
              3 * 60 * 1000
            );
          }
        }
      },
      viewerCount(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal >= 40 && !this.goals.viewers.goalHit) {
            this.goals.viewers.goalHit = true;
            this.goals.viewers.onFire = true;
            this.goals.viewers.timeoutInstance = setTimeout(
              () => (this.goals.viewers.onFire = false),
              3 * 60 * 1000
            );
          }
        }
      },
    },
    created() {
      // Poll twitch for data (viewers and followers).
      this.pollTwitchAPI();

      // Get initial subs ONCE per session. We don't want sub numbers dropping mid-session.
      this.$store.dispatch("twitch/fetchSubscribers");

      // TODO: think of a better place for this?
      // this.$store.dispatch("tasks/runTasksRemotely", [
      //   { uuid: "24910df5-4fa2-4de3-b22d-c6ed7d9b12dc", canceled: true }, // player blur off
      // ]);

      this.goals.startingCount = this.followerCount;

      /*
        how does this work?
        a streak is determined by at least 20 viewers
        a streak is every TEN viewers, assuming the count is going up
        once it goes down, it resets - cooldown occurs - then checks for 20

        * var i = x % 10 (is multiple of 10)

        * determine by quick succession in a timeframe?
        * OR milestones?

        // FOLLOW + SUB CRITERIA:
        follower/sub should be based on quick succession in short timeframe AND miletones of multiples of 10.

        0. check if initial min number has been met (if exists)
        1. every minute, check if the viewCount has gone UP since last poll
        2. check HOW much it's gone up by (threshold x3 etc)
        3. if threshold met, store 'highscore' and multiply the duration of the streak (flame) by the 'highscore'
        4. else if the number decreases, or stays level, cancel the streak - check again next minute
        * if number DECREASES and falls below the previous target, DO NOT trigger a streak until it exceeds
        * beware of 666 manual trigger
      */
    },
    beforeDestroy() {
      clearTimeout(this.twitchPollingInstance);
    },
    methods: {
      // Poll twitch API for viewer count, if a raid occurs - pause polling for 1.5 mins. Use count from the raid.
      pollTwitchAPI() {
        const POLLING_INTERVAL = window.debug ? 5 * 60 * 1000 : 6000; // Don't update as frequently on dev.

        const nextPoll = () => {
          this.twitchPollingInstance = setTimeout(this.pollTwitchAPI, POLLING_INTERVAL);
        };

        if (!this.getViewerCountPollingPaused) {
          this.$store.dispatch("twitch/fetchTwitchData").then(() => {
            console.info("Dashboard is polling Twitch... ✅");
            nextPoll();
          });
        } else {
          console.info("Twitch Polling Paused.");
          nextPoll();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  //---------------------------------------------------------
  // ENTER TRANSITION
  //---------------------------------------------------------

  .dashboardTV-enter {
    transform: translateY(10%);
    animation: dashboardTV-enter 800ms ease 300ms alternate 1 forwards;

    @keyframes dashboardTV-enter {
      to {
        transform: translateY(0);
      }
    }
  }

  //---------------------------------------------------------
  // DASHBOARD
  //---------------------------------------------------------

  .dashboard-parent {
    position: absolute;
    bottom: 0;
    width: 100%;
    // z-index: 2;
    // border: 2px dashed white;
  }

  //--------------------------------------------------------
  // DASHBOARD BG
  //---------------------------------------------------------

  .dashboard {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(121px + 7px); // 7px is the top shadow
    // border: 1px dashed aqua;
    z-index: -1;

    .dashboard-bg-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      // border: 1px dashed aqua;

      .dashboard-bg-fill {
        position: absolute;
        top: 7px;
        left: 0;
        width: 100vw;
        height: calc(100% - 7px);
        box-shadow: 0 -7px 0 0 rgba(black, 0.2);
        background: #1a1b1f;
        // background: #010101 url("./assets/img/dashboard-bg@2x.png") repeat-x;

        background: linear-gradient(to bottom, #1a1b1f 86%, darken(#29292e, 4%) 100%); // GOOD!

        // background-size: calc(3840px / 2);
        // background-position: bottom center;
      }
    }
  }

  // ACTIVE MASK
  .dashboard.is-active .dashboard-bg-mask {
    width: calc(100% - 300px);
  }

  // HARDCODE 720p
  .is-720p {
    .dashboard.is-active .dashboard-bg-mask .dashboard-bg-fill,
    .dashboard.is-active .dashboard-bg-mask .dashboard-lip,
    .dashboard.is-active .dashboard-bg-mask .dashboard-lip-bg {
      width: 1618px;
    }
  }

  //--------------------------------------------------------
  // DASHBOARD LIP
  //---------------------------------------------------------

  .dashboard-lip {
    position: absolute;
    top: 8px;
    width: 100vw;
    height: 7px;
    box-shadow: 0 2px 0 0 black;
    background-color: #2f3137; // [LOCKED]

    .dashboard-lip-bg {
      height: inherit;
      $color: desaturate(darken($primary, 10%), 30%);
      background-image: linear-gradient(
        -90deg,
        #2f3138 19%,
        $color 49%,
        $color 53%,
        #2f3138 79%
      ); // GOOD!

      background-size: calc(3840px / 2);
      background-position: top center;
      // transition: transform 1s ease;
      // transform: translateX(300px);

      .dashboard-lip-bg-shine {
        position: relative;
        left: calc(42% - #{rem-calc(420/2)});
        width: rem-calc(420);
        height: inherit;
        background-image: linear-gradient(
          -90deg,
          rgba(white, 0) 0%,
          rgba(white, 0.5) 30%,
          rgba(white, 0) 50%
        );

        transition: transform 1s ease;
        // transform: translateX(100px);

        // border: 1px dashed red;
      }
    }
  }

  //---------------------------------------------------------
  // DASHBOARD BODY
  //---------------------------------------------------------

  .dashboard-body {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: rem-calc(13);
    height: rem-calc(115); // [LOCKED]
    // border: 1px dashed orange;
  }

  //---------------------------------------------------------
  // SHADOW
  //---------------------------------------------------------

  // .dashboard-shadow {
  //     position: absolute;
  //     bottom: calc(100% - 7px);
  //     width: 100%;
  //     height: rem-calc(712/2);
  //     // border: 1px dashed aqua;
  //     z-index: -1;
  //     pointer-events: none;

  //     .dashboard-shadow-right-end {
  //         position: absolute;
  //         right: 0;
  //         width: rem-calc(800/2);
  //         height: rem-calc(712/2);
  //         background: url('./assets/img/dashboard-shadow-right-end@2x.png') no-repeat;
  //         background-size: contain;

  //         .dashboard-shadow-body {
  //             position: absolute;
  //             right: rem-calc(800/2);
  //             width: 100vw;
  //             height: rem-calc(712/2);
  //             background: url('./assets/img/dashboard-shadow@2x.png') repeat-x;
  //             background-size: contain;
  //         }
  //     }
  // }
</style>
